import { CloseIcon } from "../components/icons"

import * as ST from "@suid/types"; 
import { isExtraSmall, isSmall, isMedium } from "../../utils/sizeUtil"

import { ShowHeading } from '../components/SmallComponents/SmallHeader' 
import { For, createSignal, Show,  } from "solid-js";
import {
    Grid, IconButton,Button, Typography, Badge, 
    Card, Stack, Divider, DialogActions, Box,
} from "@suid/material";

import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { CardWithMinimize } from '../components/AppMenuBar/CardList'

import { getCompanyLogo } from '../../utils/getCompanyLogo'
import { AvatarWithWfButtons, showWfCustomerMatchingOpendItems } from '../components/SmallComponents/SmallAvatarWithWfButtons'
import { TeamMembersList } from '../components/SmallComponents/SmallFieldTeamMembers';
import { getDummyAvatar } from '../../utils/getdummyavatar' 
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'


const ShowFavouritsPage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);


    
  const pageMargin = ((isExtraSmall() || isSmall()) ? 1 : 2)
  const sharingDirection = (isExtraSmall() || isSmall() || isMedium() ? "column" : "row")
  const cardType = (isExtraSmall() || isSmall() || isMedium() ? "list" : "stack")


    const [openFavourits, setOpenFavourits] = createSignal(false);

    const handleClose = () => {
        setOpenFavourits(false);
        //navigate('/home')
        window.history.go(-1)
    }

    const [viewFromTeamMemberEnabled, setViewFromTeamMemberEnabled] = createSignal(false);
    const [filter, setFilter] = createSignal("viewAll");
    const handleFilterChange = (event: ST.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
        event.target.value === "viewFromMember" ? setViewFromTeamMemberEnabled(true) : setViewFromTeamMemberEnabled(false)
    };

    const handleOpenCV = () => {
        navigate('/customercv')
    }
    const handleOpenJob = () => {
        navigate('/customerjob')
    }

    const avatarGrossList = [
        { type: "Teddy Hansen", src: "/assets/imgs/avatar2.jpg", new: true },
        { type: "Peter Pan", src: getDummyAvatar(), new: true },
        { type: "Winny The Poo", src: getDummyAvatar() },
        { type: "Sahra Winterh", src: getDummyAvatar() },
        { type: "Kid the Bil", src: getDummyAvatar() },
        { type: "Sunshi Salmon", src: getDummyAvatar() },
        { type: "Berny Sanson", src: getDummyAvatar() },
        { type: "Kentucy Fried", src: getDummyAvatar() },
        { type: "Fried Banana", src: getDummyAvatar() },
        { type: "Carot Long", src: getDummyAvatar() },
        { type: "Åse Åen", src: getDummyAvatar() },
        { type: "Cathy Candy", src: getDummyAvatar() },
    ]
    const jobGrossList = [

        { name: "S/4 Upgrade", subText: "4 open roles", sharedBy: "Tim Hansen", comment: "We have to find a PM within 1 week.", src: getCompanyLogo("shell.com"), new: true },
        { name: "S/4 Migration", subText: "3 open roles", sharedBy: "Tim Hansen", comment: "", src: getCompanyLogo("apple.com") },
        { name: "Payroll Rollout", subText: "1 contract ready", sharedBy: "Ida Bengson", comment: "We are in a hurry. No payrol 2 months from now", src: getCompanyLogo("audi.de") },
        { Name: "AI Enablement", subText: "2 interviews", sharedBy: "Ida Bengson", comment: "", src: getCompanyLogo("bmw.com") },
        { Name: "Database Upgrade", subText: "1 extension", sharedBy: "Tim Hansen", comment: "Pls. look into", src: getCompanyLogo("volvocars.com") },

    ]

    const txtYourTeamMembers = getText("sharing", "yourteammembers")
    const txtAlert = getText("sharing", "alert", [{ name: 'newCandidates', value: '4' }, { name: 'totalCandidates', value: '20' }, { name: 'newJobs', value: '12' }, { name: 'totalJobs', value: '87' }])
    const txtCandidates = getText("default", "candidates")
    const txtComment = getText("default", "comment")
    const txtToday = getText("default", "today")
    const txtOpenRoles = getText("default", "openroles", [{ name: 'openRoles', value: '2' }])
    const txtJobs = getText("default", "jobs")
    const txtClose = getText("default", "close")
    const txtIntro = getText("sharing", "intro")
    const txtFavourits = getText("menu", "favourits")


    type AvatarItemProps = {
        item: {
            src: string,
            type?: string,
            name?: string,
            sharedBy?: string,
            comment?: string,
            new?: boolean
        },
        handleOpen: () => void,
        isCandidate: boolean
    };

    const AvatarItem = (props: AvatarItemProps) => {
        return (
            <>
                <Stack direction="column" sx={{ margin: 1 }}>
                    <Button >
                        <Show when={props.item.new} fallback={
                            <AvatarWithWfButtons avatarSize="medium" showWfItems={showWfCustomerMatchingOpendItems}
                                avatarName={props.isCandidate ? props.item.type : props.item.name}
                                jobName={""}
                                role={""}
                                src={props.item.src}
                            />}>
                            <Badge badgeContent={''} color="error">
                                <AvatarWithWfButtons avatarSize="medium" showWfItems={showWfCustomerMatchingOpendItems}
                                    avatarName={props.isCandidate ? props.item.type : props.item.name}
                                    jobName={""}
                                    role={""}
                                    src={props.item.src}
                                />
                            </Badge>
                        </Show>
                    </Button>
                    <Stack sx={{ margin: 1, alignItems: 'left', display: 'flex' }}>
                        <Divider />
                        <Typography fontSize="80%" variant="body1" color="text.primary">
                            <b>{txtComment()}</b><br />
                            {props.isCandidate ? "bl.a. bl.a." : props.item.comment}
                        </Typography>
                        <br />

                    </Stack>
                </Stack>
            </>
        )
    }

    type RenderListProps = {
        headerText: string,
        list: Array<AvatarItemProps['item']>,
        isCandidate: boolean
    };

    const RenderList = (props: RenderListProps) => {
        return (
            <Grid container item xs={12} md={6} alignItems="center">
                <CardWithMinimize header={props.headerText} type={cardType} defaultOpen={true}>
                    <Stack direction={sharingDirection} alignItems="left" spacing={1}>
                        <For each={props.list}>
                            {(item) => (
                                <AvatarItem item={item} handleOpen={props.isCandidate ? handleOpenCV : handleOpenJob} isCandidate={props.isCandidate} />
                            )}
                        </For>
                    </Stack>
                </CardWithMinimize>
            </Grid>
        )
    };

    return (
        <>

            <AccountMenu />

            <ShowHeading pxValue={4} >{txtFavourits()}</ShowHeading>

<Divider />

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Card sx={{ margin: pageMargin }}>
                  
                        <Box sx={{  display: 'flex', bgcolor: 'background.default'[200] }} >
                            <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                        </Box>
                 

 
                        <Grid container direction="column">

                            <RenderList headerText={txtCandidates() as string+ " (" + avatarGrossList.length + ")"} list={avatarGrossList} isCandidate={true} />
                            <RenderList headerText={txtJobs() as string+ " (" + jobGrossList.length + ")"} list={jobGrossList} isCandidate={false} />

                        </Grid>
                   

                    <DialogActions>
                        <Divider />
                        <Button onClick={handleClose} variant="outlined">
                            {txtClose()}
                        </Button>
                    </DialogActions>
                </Card>
            </Box>
        </>
    );
}

export { ShowFavouritsPage }